// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StartingPage_card__aVH5L {\r\n    display: flex;\r\n    width: 55rem;\r\n    padding: 3rem 1rem\r\n}", "",{"version":3,"sources":["webpack://./src/components/StartingPage/StartingPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ;AACJ","sourcesContent":[".card {\r\n    display: flex;\r\n    width: 55rem;\r\n    padding: 3rem 1rem\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "StartingPage_card__aVH5L"
};
export default ___CSS_LOADER_EXPORT___;
