// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Panel_toolbar__rtdpU {\r\n    background-color:  #7D9D9C;\r\n    width:100%;\r\n    display:flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 18%;\r\n    padding: 0.5rem 0rem;\r\n}\r\n\r\n.Panel_icon__1a8OS:hover {\r\n    cursor: pointer;\r\n    fill:  #354D51 ;\r\n\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/components/Panel/Panel.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,eAAe;;AAEnB","sourcesContent":[".toolbar {\r\n    background-color:  #7D9D9C;\r\n    width:100%;\r\n    display:flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 18%;\r\n    padding: 0.5rem 0rem;\r\n}\r\n\r\n.icon:hover {\r\n    cursor: pointer;\r\n    fill:  #354D51 ;\r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": "Panel_toolbar__rtdpU",
	"icon": "Panel_icon__1a8OS"
};
export default ___CSS_LOADER_EXPORT___;
