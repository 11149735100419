// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navigation_navbar__O-QuA {\r\n  background-color: #576f72 !important;\r\n  padding: 1rem 0 !important;\r\n  align-items: center;\r\n}\r\n\r\n.Navigation_info__oUylO {\r\n  height: 3rem;\r\n  width: auto;\r\n  position: fixed;\r\n  top: 6rem;\r\n  right: 3rem;\r\n}\r\n\r\n.Navigation_info__oUylO:hover {\r\n   transform: scale(1.1);\r\n}\r\n\r\n/* height: '3rem',\r\nwith: 'auto',\r\nposition: 'fixed',\r\ntop: ' 5rem',\r\nright: '2rem',\r\n'&:hover' : {\r\n  backgroundColor:'white'\r\n} */\r\n", "",{"version":3,"sources":["webpack://./src/components/Layout/Navigation.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,SAAS;EACT,WAAW;AACb;;AAEA;GACG,qBAAqB;AACxB;;AAEA;;;;;;;GAOG","sourcesContent":[".navbar {\r\n  background-color: #576f72 !important;\r\n  padding: 1rem 0 !important;\r\n  align-items: center;\r\n}\r\n\r\n.info {\r\n  height: 3rem;\r\n  width: auto;\r\n  position: fixed;\r\n  top: 6rem;\r\n  right: 3rem;\r\n}\r\n\r\n.info:hover {\r\n   transform: scale(1.1);\r\n}\r\n\r\n/* height: '3rem',\r\nwith: 'auto',\r\nposition: 'fixed',\r\ntop: ' 5rem',\r\nright: '2rem',\r\n'&:hover' : {\r\n  backgroundColor:'white'\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "Navigation_navbar__O-QuA",
	"info": "Navigation_info__oUylO"
};
export default ___CSS_LOADER_EXPORT___;
